<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="2"/>
        <b-col cols="8">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Hostname..."/>
              </b-col>
            </b-row>
          </SubHeader>
          <b-list-group :class="listGroupClasses" style="font-size: 14px">
            <b-row cols="1">
              <b-col>
                <b-list-group-item v-for="entry in processList" :key="entry.id" class="list-row">
                  <b-row>
                    <b-col cols="2">
                      {{ entry.hostname }}
                    </b-col>
                    <b-col v-if="entry.customer" :id="'tt-user-' + entry.customer.id" cols="auto">
                      {{ entry.customer.zuname }}, {{ entry.customer.vorname }}
                      <b-tooltip :target="'tt-user-' + entry.customer.id" custom-class="tooltip-width-100" triggers="hover">
                        <div class="text-left">
                          {{ entry.customer.kdnr }}<br>
                          {{ entry.customer.kurs }}<br>
                          Klassenraum-{{ entry.customer.intklr }}
                        </div>
                      </b-tooltip>
                    </b-col>
                  </b-row>
                  <b-row cols="1" class="mt-2">
                    <div v-for="process in entry.processes" :key="process.id" class="px-3 mb-1" style="font-size: 12px">
                      <ProcessEntry :process="process" @acknowledge="acknowledge(process)"
                                    @allow-temp="allowTemp(process)" class="ml-3 mr-2"/>
                    </div>
                  </b-row>
                </b-list-group-item>
              </b-col>
            </b-row>
          </b-list-group>
          <b-row v-if="processList.length === 0" cols="1">
            <b-col>
              <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
    <ProcessModal :process="process" @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import ScrollToTop from "@/components/utility/ScrollToTop";
import ProcessEntry from "@/components/lists/processList/ProcessEntry";
import ProcessModal from "@/components/lists/processList/ProcessModal.vue";
import SubHeader from "@/components/structure/SubHeader";

export default {
  name: "ProcessList",
  components: {
    SubHeader,
    ProcessModal,
    ProcessEntry,
    ScrollToTop,
  },
  data() {
    return {
      search: '',
      process: null
    }
  },
  methods: {
    ...mapActions(['selectSite']),
    ...mapActions(['getProcesses']),
    ...mapActions(['resetProcesses']),
    ...mapActions(['setLoadingState']),
    async refresh() {
      this.setLoadingState(true)
      await this.getProcesses(this.siteSelected)
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    },
    async acknowledge(process) {
      await putRequest('processes/acknowledge/' + process.id, null, this, 'Der Prozess wurde erfolgreich zur Kenntnis genommen.')
          .then(() => {
            this.refresh()
          })
    },
    allowTemp(process) {
      this.process = process;
      this.$bvModal.show('processModal')
    },
  },
  computed: {
    ...mapGetters(['siteSelected']),
    ...mapGetters(['processes']),
    ...mapGetters(['userThemeId']),
    listGroupClasses() {
      if(this.userThemeId === 2) {
        return ['mt-2']
      }
      return ['text-white', 'mt-2']
    },
    processList() {
      return _.filter(this.processes, (entry) => {
        if (entry.hostname.toLowerCase().includes(this.search.toLowerCase())) {
          return true
        }
      })
    },
    hostname() {
      if (this.$route.query.hostname) {
        return this.$route.query.hostname
      }
      return null
    }
  },
  created() {
    this.refresh()
    if (this.$route.query && this.$route.query.hostname) {
      this.search = this.hostname
    }
  },
  beforeMount() {
    if(this.$route.query && this.$route.query.site) {
      this.selectSite(this.$route.query.site)
    }
    this.resetProcesses();
  }
}
</script>

<style lang="scss" scoped>
</style>
