<template>
  <div>
    <b-row cols="2" @mouseenter="hover = true" @mouseleave="hover = false"
           :class="[process.process_block ? 'process-entry-danger' : 'process-entry-warning', {rounded: !expand}]">
      <b-col cols="10" class="text-left pl-1">
        <span @click="expand = !expand" class="clickable process-link">{{ process.path }}</span>
      </b-col>
      <b-col cols="2" class="text-right pr-1">
        <div v-if="hover">
          <ProcessButtonGroup :process="process" @acknowledge="acknowledge" @allow-temp="allowTemp"/>
        </div>
        <div v-else class="process-count">
          {{ process.count }}x
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="expand" cols="12" class="process-details">
        <b-row v-if="process.process_block" class="mt-2">
          <b-col cols="auto">
            <b-icon-exclamation-triangle-fill :variant="variantDanger" scale="1.5" class="ml-1 mr-1"/>
            Dieser Prozess ist aus folgendem Grund nicht erlaubt: <br>{{ process.process_block.description }}
          </b-col>
        </b-row>
        <b-row>
          <LastTimestamps :object="process"/>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LastTimestamps from "@/components/lists/LastTimestamps";
import ProcessButtonGroup from "@/components/lists/processList/utility/ProcessButtonGroup";
import {mapGetters} from "vuex";

export default {
  name: "ProcessEntry",
  components: {
    LastTimestamps,
    ProcessButtonGroup
  },
  props: ['process'],
  data() {
    return {
      hover: false,
      expand: false,
    }
  },
  methods: {
    acknowledge() {
      this.$emit('acknowledge', this.process)
    },
    allowTemp() {
      this.$emit('allow-temp', this.process)
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/ProcessEntry.scss';
</style>
